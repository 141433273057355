import React from 'react'

const ErrorPage = () => {
	return (
		<div>
			swag			
		</div>
	)
}

export default ErrorPage 